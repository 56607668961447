function fallbackCopyTextToClipboard (text) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  let error = null
  let successful = 'unsuccessful'

  try {
    successful = document.execCommand('copy')
  } catch (err) {
    error = err
  }
  document.body.removeChild(textArea)
  if (error) {
    throw error
  } else {
    return successful === 'successful'
  }
}

export function copyTextToClipboard (text) {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      try {
        const result = fallbackCopyTextToClipboard(text)
        resolve(result)
      } catch (err) {
        reject(err)
      }
    }
    navigator.clipboard.writeText(text).then(function () {
      resolve(true)
    }, (err) => {
      reject(err)
    })
  })
}
