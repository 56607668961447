import moment from 'moment'

export function getMMM (valor) {
  return moment(valor).format('MMM')
}

export function getYYYY (valor) {
  return moment(valor).format('YYYY')
}

export function standardDate (valor) {
  return moment(valor).format('DD-MMM-YYYY')
}

export function standardTime (valor) {
  return moment(valor).format('HH:mm:ss')
}

export function largeDate (valor) {
  return moment(valor).format('DD-MMMM-YYYY')
}

export function completeDate (valor) {
  return moment(valor).format('DD, MMMM YYYY - h:mm:ss a')
}

export function dateAgo (valor) {
  // return moment(valor).format('DD-MMMM-YYYY')
  return moment(valor).fromNow()
}

export function monthDay (valor) {
  return moment(valor).format('MM/DD')
}
