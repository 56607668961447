<template>
  <v-snackbar
    :value="state"
    :color="colorMessage"
    :timeout="data && data.duration ? data.duration : 1000"
  >
    <v-icon left small>{{ iconMessage }}</v-icon>
    <span>{{ data && data.message ? data.message : 'NA' }}</span>
  </v-snackbar>
</template>

<script>
export default {
  name: 'NotificationsToast',
  // emits: ['update:state'],
  props: {
    data: {
      type: Object,
      required: false
    },
    state: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    colorMessage: 'primary',
    iconMessage: 'info'
  }),
  watch: {
    state (newVal) {
      if (newVal) {
          this.openToast()
      }
    }
  },
  methods: {
    openToast() {
      let icon = 'fa-information-circle'
      let color = 'primary'
      if (this.data && this.data.message) {
        if (this.data.type) {
          switch (this.data.type) {
            case 'success':
              icon = 'fa-circle-check'
              color = 'success'
              break
            case 'info':
              icon = 'fa-circle-info'
              break
            case 'error':
              icon = 'fa-bug' 
              color = 'error'
              break
            case 'warning':
              icon = 'fa-triangle-exclamation' 
              color = 'warning'
              break
            default:
              icon = 'fa-circle-info'
              break
          }
        }
        this.colorMessage = color
        this.iconMessage = icon
        setTimeout(() => {
          this.$emit('update:state', false)
          this.$emit('update:data', null)
        }, this.data.duration + 100 );
      } else {
        this.$emit('update:state', false)
      }
    }
  }
}
</script>
<style scoped>
</style>
