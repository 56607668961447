<template>
  <v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
    <v-toolbar
			flat
			dense
			dark
			color="primary"
		>
			<v-btn
				icon
				dark
				@click="closeForm()"
			>
				<v-icon>fa-times</v-icon>
			</v-btn>
			<v-toolbar-title class="font-weight-bold white--text">Ver item</v-toolbar-title>
			<v-spacer></v-spacer>
		</v-toolbar>
		<v-card tile>
      <v-row dense class="justify-center">
        <v-col cols="6" xl="3">
          <v-avatar
            :key="dataItem.id"
            tile
            :height="heightImg"
            width="100%"
          >
            <v-img
              eager
              referrerpolicy="no-referrer"
              v-bind:src="photoURL"
              class="image-item"
              lazy-src="/no_image.png"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0" align="center" justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    size="30"
                    width="2"
                    color="grey"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row class="justify-center">
          <v-col cols="6">
            {{ dataItem }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
	</v-dialog>
</template>
<script>

import imageFunction from '@/functions/imageFunction'

export default {
	name: 'ItemMenuDetail',
	props: {
		dataItem: {
			type: Object,
			require: true
		},
		state: {
			type: Boolean,
			require: true
		}
	},
	data: () => ({
		dialog: null,
    loading: false,
    photoURL: null
	}),
  computed: {
    heightImg () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return '450'
        case 'lg':
          return '250'
        case 'md':
          return '200'
        case 'sm':
          return '250'
        default:
          return '185'
      }
    }
  },
	watch: {
		state (newVal) {
			if (newVal) {
				this.dialog = newVal
			}
		},
		dialog (newVal) {
			if (!newVal) {
				this.$emit('update:state', false)
			}
		},
    dataItem () {
      this.getImage()
    }
	},
	created () {
		this.dialog = this.state
	},
  mounted () {
    this.getImage()
  },
  methods : {
    async getImage () {
			this.photoURL = await imageFunction.getImageStore('inventario', this.dataItem.id, '.webp', 'lg')
		},
    closeForm() {
      this.dialog = false
    }
  }
}
</script>
<style>
</style>
