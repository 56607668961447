import Axios from 'axios'
import Vue from 'vue'


let baseURL = 'https://celcer.sri.gob.ec/'

Vue.prototype.$axiosSRI = Axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/xml',
    "Access-Control-Allow-Origin": "https://taked.app/"
  }
})