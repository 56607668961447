import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import createStore from '@/store'
import accessControl from '../middleware/accessControl';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/remember',
    name: 'remember',
    component: () => import('../views/RememberView.vue')
  },
  {
    path: '/export-csv',
    name: 'export-csv',
    component: () => import('@/views/app/utils/ExportCsvView'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/app/DashboardApp.vue'),
    meta: {
      autenticado: true
      // middleware: accessControl
    }
  },
  {
    path: '/backwindow',
    name: 'backwindow',
    component: () => import('@/views/app/backwindow/BackWindow.vue'),
    meta: {
      autenticado: true
      // middleware: accessControl
    }
  },
  {
    path: '/admin-orders',
    name: 'admin-orders',
    component: () => import('@/views/app/orders/AdminOrdensView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/my-orders',
    name: 'my-orders',
    component: () => import('@/views/app/orders/MyOrdensView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/order-divider',
    name: 'order-divider',
    component: () => import('@/views/app/orders/OrderDividerView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import('@/views/app/orders/OrderView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/take-order',
    name: 'ordenes',
    component: () => import('@/views/app/comanda/TakeComandaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/take-order-board',
    name: 'take-order-board',
    component: () => import('@/views/app/comanda-onboard/TakeComandaOnBoardView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kiosko',
    name: 'kiosko',
    component: () => import('@/views/app/comanda/KioskoTakeComandaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/app/config/CategoriesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ingredientes',
    name: 'ingredientes',
    component: () => import('@/views/app/ingredientes/IngredientesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ingrediente/:id',
    name: 'ingrediente',
    component: () => import('@/views/app/ingredientes/ItemIngredienteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/detailingrediente/:id',
    name: 'detailingrediente',
    component: () => import('@/views/app/ingredientes/IgredienteDetailView'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kardex',
    name: 'kardex',
    component: () => import('@/views/app/inventario/ItemKardexView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ingresos-egresos',
    name: 'ingresos-egresos',
    component: () => import('@/views/app/inventario/IngresosEgresosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/inventario',
    name: 'inventario',
    component: () => import('@/views/app/inventario/InventarioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/item/:id',
    name: 'item',
    component: () => import('@/views/app/inventario/ItemInventarioView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/cambiarimpuestos',
    name: 'cambiarimpuestos',
    component: () => import('@/views/app/inventario/CambiarImpuestosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/proveedores',
    name: 'proveedores',
    component: () => import('@/views/app/proveedores/ProveedoresView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/proveedor/:id',
    name: 'proveedor',
    component: () => import('@/views/app/proveedores/ItemProveedorView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/app/users/UserProfile.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('../views/app/config/GeneralConfigurationView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/configurar-utils',
    name: 'configurar-utils',
    component: () => import('../views/app/config/UtilsConfigurationView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/mesa/:id',
    name: 'mesa',
    component: () => import('../views/app/mesas/ItemMesaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/mesas',
    name: 'mesas',
    component: () => import('../views/app/mesas/MesasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/rol',
    name: 'rol',
    component: () => import('../views/app/roles/GestionRol.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/rol/asignar-ruta',
    name: 'rolRuta',
    component: () => import('../views/app/roles/AsignarRutas.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/app/users/ManageUsers.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('../views/app/facturacion/PayPhoneView.vue'),
    // component: () => import('../views/app/users/ManagePassword.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/users/form',
    name: 'usersForm',
    component: () => import('../views/app/users/FormView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kitchen-room',
    name: 'kitchen-room',
    component: () => import('../views/app/comanda/KitchenView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/bar-room',
    name: 'bar-room',
    component: () => import('../views/app/comanda/BarRoomView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: () => import('../views/app/clientes/ClientView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/cliente/:id',
    name: 'cliente',
    component: () => import('../views/app/clientes/ItemClienteView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  // {
  //   path: '/facturacion',
  //   name: 'facturacion',
  //   component: () => import('../views/app/FacturacionElectronica.vue'),
  //   meta: {
  //     autenticado: true,
  //     middleware: accessControl
  //   }
  // },
  {
    path: '/facturacion/emitir-comprobante/:ordenId',
    name: 'facturacion',
    component: () => import('../views/app/facturacion/FacturacionView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/facturacion/emitir-recibo/:ordenId',
    name: 'recibo',
    component: () => import('../views/app/facturacion/RecibosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/facturacion-manual',
    name: 'facturacion-manual',
    component: () => import('../views/app/facturacion/FactuacionManualView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/comprobantes',
    name: 'comprobantes',
    component: () => import('../views/app/facturacion/ComprobantesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/ordenespagadas',
    name: 'ordenespagadas',
    component: () => import('../views/app/facturacion/OrdernesSoloPagadasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/items-eliminados',
    name: 'items-eliminados',
    component: () => import('../views/app/fiscalizacion/ItemsEliminadosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/items-cortesia',
    name: 'items-cortesia',
    component: () => import('../views/app/fiscalizacion/ItemsRegaladosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/app/NotificationsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/prints',
    name: 'prints',
    component: () => import('../views/app/PrintsView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: () => import('../views/app/ingredientes/IngredientesStockView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/gestionar-caja',
    name: 'gestionar-caja',
    component: () => import('../views/app/cajas/GestionarCajaView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/detalle/:id',
    name: 'detalleCaja',
    component: () => import('../views/app/cajas/DetalleCajasView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/egresos-caja',
    name: 'egresos-caja',
    component: () => import('../views/app/cajas/EgresosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/egreso-caja/:id',
    name: 'egreso-caja',
    component: () => import('../views/app/cajas/ItemEgresoView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/caja/detalle/transacciones/:id',
    name: 'detalleTransacciones',
    component: () => import('../views/app/cajas/DetalleTransaccionesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kpi/topvendidos',
    name: 'topvendidos',
    component: () => import('../views/app/kpis/TopVendidosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kpi/usoinventario',
    name: 'usoinventario',
    component: () => import('../views/app/kpis/InventarioOrdenesView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/kpi/usoingredientes',
    name: 'usoingredientes',
    component: () => import('../views/app/kpis/IngredientesKardexView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/fidelizacion/parametros-fidelizacion',
    name: 'parametros-fidelizacion',
    component: () => import('../views/app/fidelizacion/ParametrosFidelizacionView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/tipos-descuentos',
    name: 'tipos-descuentos',
    component: () => import('../views/app/config/DescuentosView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  },
  {
    path: '/payphone',
    name: 'payphone',
    component: () => import('../views/app/payphone/ComprobantesPayPhoneView.vue'),
    meta: {
      autenticado: true,
      middleware: accessControl
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  try {
    let usuario = null;
    await createStore().then((store) => {
      usuario = store.getters.logeado;
    });

    let autorizacion = to.matched.some((record) => record.meta.autenticado);
    if (autorizacion && !usuario) {
      next("/");
    } else if (!autorizacion && usuario) {
      next("/dashboard");
    } else if (autorizacion && usuario) {
      if (to.meta.middleware) {
        const middleware = to.meta.middleware;
        middleware({ next, to });
      } else {
        next();
      }
    } else {
      next();
    }
  } catch (er) {
    console.error('ROUTER ->', er)
  }
});

export default router
