<template>
  <v-footer
    absolute
    app
    dark
    color="primary"
    class="white--text"
  >
    <v-container fluid>
      <v-row v-if="negocio" dense class="justify-center">
        <v-col
          cols="12"
          class="text-center"
        >
          <p class="mb-0 title font-weight-black">{{ negocio.nombreMenuDigital }}</p>
        </v-col>
        <v-col
          cols="12"
          class="text-center"
        >
          <span v-if="negocio.telefonoContacto && negocio.telefonoContacto.length" class="caption">
            <v-icon left small class="mt-n1">
              fa-mobile
            </v-icon>
            {{ negocio.telefonoContacto }}
          </span>
          <span v-if="negocio.telefonoContacto && negocio.telefonoContacto.length" class="mx-2">
          -
          </span>
          <span v-if="negocio.telefono && negocio.direccion.length" class="caption">
            <v-icon left small class="mt-n1">
              fa-location-dot
            </v-icon>
            {{ negocio.direccion }}
          </span>
        </v-col>
        <v-col
          v-for="(red, i) in social"
          :key="i"
          cols="auto"
        >
          <a
            class="caption f-resaltarlink white--text font-weight-bold mx-2"
            :href="red.link"
            target="_blank"
            v-text="red.nombre"
          />
        </v-col>
      </v-row>
      <v-divider v-if="negocio" class="my-1"></v-divider>
      <v-row dense class="justify-center">
        <v-col
          class="subtitle-1 text-center"
          cols="auto"
        >
          <v-avatar
            tile
            size="42"
            style="cursor: pointer;"
            @click="toIndex"
          >
            <v-img
              src="/logo-dark.png"
              contain
            >
            </v-img>
          </v-avatar>
          <a class="ml-1 caption font-weight-light white--text" style="text-decoration:none" href="https://taked.app" target="_blank">Copyright &copy; {{ (new Date()).getFullYear() }}</a>
          <span class="ml-3">-</span>
          <v-btn
            text
            target="_blank"
            href="https://api.whatsapp.com/send?phone=593987679471&text=Saludos,%20necesito%20informaci%C3%B3n%20de%20*TAKED*.%20Mi%20nombre%20es%20"
            style="text-transform:none"
          >
            <v-icon x-small left>fa-brands fa-whatsapp</v-icon>
            0987-679-471
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FooterSite',
  data: () => ({
  }),
  computed: {
    ...mapGetters(['negocio']),
    isMenu () {
      return this.$route.name === '/home'
    },
    social () {
      const auxSocial = []
      if (this.negocio) {
        if (this.negocio.facebookId && this.negocio.facebookId.length) {
          auxSocial.push({
            nombre: 'Facebook',
            link: 'https://www.facebook.com/' + this.negocio.facebookId
          })
        }
        if (this.negocio.tiktokId && this.negocio.tiktokId.length) {
          auxSocial.push({
            nombre: 'Tiktok',
            link: 'https://www.tiktok.com/' + this.negocio.tiktokId
          })
        }
        if (this.negocio.instagramId && this.negocio.instagramId.length) {
          auxSocial.push({
            nombre: 'Instagram',
            link: 'https://www.instagram.com/' + this.negocio.instagramId
          })
        }
      }
      return auxSocial
    }
  },
  methods: {
    toIndex () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
.f-resaltarlink {
  text-decoration: none;
  display: inline-block;
  position: relative;
}
.f-resaltarlink:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #ffffff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition-delay: 50ms;
  width: 0;
}
.f-resaltarlink:hover:after {
  transition-delay: 0.2s;
  width: 100%;
  left: 0%;
}
</style>
