<template>
  <v-snackbar
    bottom
    right
    multi-line
    :value="updateExists"
    :timeout="-1"
    color="primary"
  >
    <v-icon dark left>
      fa-cloud-arrow-down
    </v-icon>
    Tenemos una nueva Versión disponible.
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        outlined
        text
        @click="refreshApp"
      >
        Actualizar
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
  export default {
    name: 'UpdateApp',
    data: () => ({
      refreshing: false,
      registration: null,
      updateExists: false
    }),
    methods: {
      updateAvailable (event) {
        this.registration = event.detail
        this.updateExists = true
      },
      refreshApp () {
        this.updateExists = false
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!this.registration || !this.registration.waiting) return
        // Send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
    created () {
      // Listen for our custom event from the SW registration
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
    }
  }
</script>
