<template>
  <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <v-card :loading="loading">
        <v-card-title class="headline primary white--text py-2">
          <span>
            <v-icon left dark class="mt-n2">
              fa-calendar-check
            </v-icon>
            HACER RESERVACIÓN
          </span>
        </v-card-title>
        <v-card-text class="py-3 text--primary">
          <v-row>
            <v-col cols="6">
              <v-menu
                ref="menuDate"
                v-model="menuDate"
                :close-on-content-click="false"
                :return-value.sync="form.fecha"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.fecha"
                    label="Seleccionar fecha"
                    prepend-icon="fa-calendar-day"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.fecha"
                  no-title
                  scrollable
                >
                  <v-row dense>
                    <v-col cols="6">
                      <v-btn
                        depressed
                        block
                        @click="menuDate = false"
                      >
                        Cancelar
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        depressed
                        block
                        color="primary"
                        @click="$refs.menuDate.save(form.fecha)"
                      >
                        Seleccinar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menuTime"
                v-model="menuTime"
                :close-on-content-click="false"
                :return-value.sync="form.hora"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.hora"
                    label="Seleccionar hora"
                    prepend-icon="fa-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="form.hora"
                  no-title
                  scrollable
                >
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-btn
                        depressed
                        block
                        class="white--text"
                        @click="menuTime = false"
                      >
                        Cancelar
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-btn
                        depressed
                        block
                        color="primary"
                        @click="$refs.menuTime.save(form.hora)"
                      >
                        Seleccinar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                v-model="form.ninos"
                :items="numeroAdultos"
                label="Número de adultos"
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="form.adultos"
                :items="numeroNinos"
                label="Número de niños"
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.nombre"
                label="A nombre de"
                hide-details
                v-uppercase
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.celular"
                label="Teléfono de contacto"
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-icon x-small left>fa-info-circle</v-icon> <span class="caption">Por favor complete el formulario y haga clic en <span class="overline">RESERVAR</span>. Nos contactaremos con Usted lo antes posible para verificar su reserva. Este proceso esta sujeto a la disponibilidad y póliticas internas.</span>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <v-card-actions>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-btn
                block
                depressed
                :loading="loading"
                @click="dialog = false"
              >
                <v-icon small left>
                  fa-times
                </v-icon>
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                block
                color="success"
                depressed
                :disabled="!puedeGuardar"
                :loading="loading"
                @click="saveData"
              >
                <v-icon small left>
                  fa-check
                </v-icon>
                Reservar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <notifications-toast
        :data.sync="dataMessage"
        :state.sync="stateMessage"
      />
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { db } from '@/plugins/firebase'
import { collection, addDoc, serverTimestamp } from "firebase/firestore"
import NotificationsToast from '@/components/base/NotificationsToast.vue'
import estadosReserva from '@/persistence/estadosReserva'

export default {
  name: 'AgendarReserva',
  components:{
    NotificationsToast
  },
  props: {
    state:{
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    estadosReserva,
    dialog: null,
    loading: false,
    show: false,
    menuDate: null,
    menuTime: null,
    numeroAdultos: [0, 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
    numeroNinos: [0, 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
    form: {
      fecha: null,
      hora: null,
      adultos: 0,
      ninos: 0,
      nombre: null,
      celular: null
    },
    dataMessage: {},
    stateMessage: false
  }),
  computed: {
    ...mapGetters(['negocio', 'logo']),
    puedeGuardar() {
      if (this.form.fecha && this.form.hora && this.form.nombre && this.form.celular && (this.form.adultos || this.form.ninos)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    state (newVal) {
			if (newVal) {
				this.dialog = true
			}
		},
		dialog (newVal) {
			if (!newVal) {
				this.$emit('update:state', false)
			}
		}
  },
  created () {
    this.dialog = this.state
  },
  methods: {
    async saveData () {
      try {
        this.loading = true
        await addDoc(collection(db, "reservas"), {
          estado: this.estadosReserva[0].value,
          fecha: this.form.fecha,
          hora: this.form.hora,
          adultos: this.form.adultos,
          ninos: this.form.ninos,
          celular: this.form.celular,
          update: new serverTimestamp,
          date: new serverTimestamp,
        })
        .then((data) => {
          this.enviarCorreo(Date(data.date).toString())
        })
      } catch {
        this.loading = false
        this.dataMessage = {
          message: 'No se pudo registrar la reserva',
          duration: 3000,
          type: 'error'
        }
        this.stateMessage = true
      }
    },
    htmlTemplate (fecha) {
      return '<div style="background-color: #044767; color: #ffffff; padding: 2px;">'
        + '<h2 style="text-align: center;">Solicitud de reserva</h2>'
        + '</div>'
        + '<p style="text-align: center;"><span style="color: #333333;"><img src="'+ this.logo +'" alt="" width="80" height="80" /></span></p>'
        + '<p><strong><span style="color: #808080;">Se ha registrado una nueva solicitud de reserva con la siguiente informaci&oacute;n:</span></strong></p>'
        + '<h3 style="padding: 10px 5px; background-color: #eeeeee;"><strong>A nombre de: <span style="color: #044767;">'+ this.form.nombre +'</span></strong></h3>'
        + '<hr />'
        + '<div style="padding: 25px 5px; background-color: #eeeeee;">'
        + '<div style="margin-bottom: 10px;">Para el: <span style="color: #044767;"><strong>'+ this.form.fecha +'</strong></span>, a las: <span style="color: #044767;"><strong>'+ this.form.hora +'</strong></span></div>'
        + '<div  style="margin-bottom: 20px;">Personas: <span style="color: #044767;"><strong>'+ this.form.adultos +'</strong> </span>adultos y <span style="color: #044767;"><strong>'+ this.form.ninos +'</strong> </span>ni&ntilde;os.</div>'
        + '<div>Contactar al: <h3 style="margin-left:10px;color: #044767; display: inline">'+ this.form.celular +'</h3></div></div>'
        + '<div style="margin-top: 20px; background-color: #000000; color: #ffff; padding: 1px;">'
        + '<h4 style="text-align: center;">Notificaci&oacute;n autogenerada por Taked</h4>'
        + '<h5 style="text-align: center;">'+ fecha +'</h5>'
        + '</div>'
    },
    async enviarCorreo(fecha) {
			try{
        if (this.negocio.emailReservas) {
          await addDoc(collection(db, "mail"), {
            to: this.negocio.emailReservas,
            message: {
              subject: '🗓️ Solicitud de Reserva',
              html: this.htmlTemplate(fecha)
            }
          })
          .then(() => {
            this.loading = false
            this.dataMessage = {
              message: 'Solicitud de reserva registrada con éxito. Nos comunicaremos a la brevedad posible.',
              duration: 3000,
              type: 'info'
            }
            this.stateMessage = true
            setTimeout(()=>{
            this.dialog = false
          }, 2500)
          })
        } else {
          this.dataMessage = {
            message: 'No se pudo registrar la reserva',
            duration: 3000,
            type: 'warning'
          }
          this.stateMessage = true
        }
			} catch (err) {
				console.error ('Error')
			}
		}
  }
}
</script>
<style>
</style>