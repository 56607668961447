<template>
  <v-overlay
    :z-index="0"
    :value="errorType"
  >
    <v-card
      v-if="errorType === 'permission-denied'"
      width="500"
      color="error"
    >
      <v-card-text class="white--text pt-8">
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <v-icon color="white" large>fa-solid fa-database</v-icon>
            <div class="ml-5">
              <p class="mb-0 subtitle-1 one-line toguether-text">Se perdio la conexión con la Base de Datos.</p>
              <p class="mb-1 subtitle-1 one-line toguether-text">Revise su estado de cuenta.</p>
              <p class="mb-0 font-weight-light">O contáctese con Soporte.</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-row dense class="justify-center">
          <v-col cols="auto">
            <v-btn
              outlined
              @click="refreshSite"
            >
              Refrescar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: 'NoServiceAvailable',
  props: {
    errorType: {
      type: String,
      require: false,
      default: null
    }
  },
  methods: {
    refreshSite () {
      window.location.reload()
    }
  }
}
</script>
<style>
</style>