// const TipoIdentificacionEnum = [
//   "CEDULA",
//   "RUC_PERSONA_NATURAL",
//   "RUC_SOCIEDAD_PRIVADA",
//   "RUC_SOCIEDAD_PUBLICA"
// ]

export function ValidacionCedulaRucService (identificacion) {
  return esIdentificacionValida(identificacion)
}

function esIdentificacionValida(identificacion) {
  if (isNullOrEmpty(identificacion)) {
    return false;
  } else {
    const longitud = identificacion.length;
    esNumeroIdentificacionValida(identificacion, longitud);

    if (longitud === 10) {
      return esCedulaValida(identificacion);
    } else if (longitud === 13) {
      const tercerDigito = parseInt(
        identificacion.substring(2, 3),
        10
      );

      if (0 <= tercerDigito && tercerDigito <= 5) {
        return esRucPersonaNaturalValido(identificacion);
      } else if (6 === tercerDigito) {
        return esRucSociedadPublicaValido(identificacion);
      } else if (9 === tercerDigito) {
        return esRucSociedadPrivadaValido(identificacion);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

function esCedulaValida(numeroCedula) {
  const esIdentificacionValida = validacionesPrevias(
    numeroCedula,
    10,
    "CEDULA"
  );

  if (esIdentificacionValida) {
    const ultimoDigito = parseInt(numeroCedula.charAt(9), 10);
    return algoritmoVerificaIdentificacion(
      numeroCedula,
      ultimoDigito,
      "CEDULA"
    );
  } else {
    return false;
  }
}

// function esRucValido(numeroRuc) {
//   return (
//     esRucPersonaNaturalValido(numeroRuc) ||
//     esRucSociedadPrivadaValido(numeroRuc) ||
//     esRucSociedadPublicaValido(numeroRuc)
//   )
// }

function esRucPersonaNaturalValido(numeroRuc) {
  const esIdentificacionValida = validacionesPrevias(
    numeroRuc,
    13,
    "RUC_PERSONA_NATURAL"
  );

  if (esIdentificacionValida) {
    const ultimoDigito = parseInt(numeroRuc.charAt(9), 10);
    return algoritmoVerificaIdentificacion(
      numeroRuc,
      ultimoDigito,
      "RUC_PERSONA_NATURAL"
    );
  } else {
    return false;
  }
}

function esRucSociedadPrivadaValido(numeroRuc) {
  const esIdentificacionValida = validacionesPrevias(
    numeroRuc,
    13,
    "RUC_SOCIEDAD_PRIVADA"
  );
  if (esIdentificacionValida) {
    const ultimoDigito = parseInt(numeroRuc.charAt(9), 10);
    return algoritmoVerificaIdentificacion(
      numeroRuc,
      ultimoDigito,
      "RUC_SOCIEDAD_PRIVADA"
    );
  } else {
    return false;
  }
}

function esRucSociedadPublicaValido(numeroRuc) {
  const esIdentificacionValida = validacionesPrevias(
    numeroRuc,
    13,
    "RUC_SOCIEDAD_PUBLICA"
  );
  if (esIdentificacionValida) {
    const ultimoDigito = parseInt(numeroRuc.charAt(8), 10);
    return algoritmoVerificaIdentificacion(
      numeroRuc,
      ultimoDigito,
      "RUC_SOCIEDAD_PUBLICA"
    );
  } else {
    return false;
  }
}

function isNullOrEmpty(contenido) {
  return undefined === contenido || null === contenido || '' === contenido;
}

function validacionesPrevias(
  identificacion,
  longitud,
  tipoIdentificacion
) {
  if ("CEDULA" === tipoIdentificacion) {
    return (
      esNumeroIdentificacionValida(identificacion, longitud) &&
      esCodigoProvinciaValido(identificacion) &&
      esTercerDigitoValido(identificacion, tipoIdentificacion)
    );
  } else {
    return (
      esNumeroIdentificacionValida(identificacion, longitud) &&
      esCodigoProvinciaValido(identificacion) &&
      esTercerDigitoValido(identificacion, tipoIdentificacion) &&
      esCodigoEstablecimientoValido(identificacion)
    );
  }
}

function esNumeroIdentificacionValida(
  numeroIdentificacion,
  longitud
) {
  return (
    numeroIdentificacion.length === longitud &&
    /^\d+$/.test(numeroIdentificacion)
  );
}

function esCodigoProvinciaValido(numeroCedula) {
  const numeroProvincia = parseInt(numeroCedula.substring(0, 2), 10);
  return (numeroProvincia > 0 && numeroProvincia <= 24) || (numeroProvincia === 30);
}

function esCodigoEstablecimientoValido(numeroRuc) {
  const ultimosTresDigitos = parseInt(
    numeroRuc.substring(10, 13),
    10
  );
  return !(ultimosTresDigitos < 1);
}

function esTercerDigitoValido(
  numeroCedula,
  tipoIdentificacion
) {
  const tercerDigito = parseInt(numeroCedula.substring(2, 3), 10);

  if (tipoIdentificacion === "CEDULA") {
    return esTercerDigitoCedulaValido(tercerDigito);
  }

  if (tipoIdentificacion === "RUC_PERSONA_NATURAL") {
    return verificarTercerDigitoRucNatural(tercerDigito);
  }

  if (tipoIdentificacion === "RUC_SOCIEDAD_PUBLICA") {
    return verificarTercerDigitoRucPublica(tercerDigito);
  }

  if (tipoIdentificacion === "RUC_SOCIEDAD_PRIVADA") {
    return verificarTercerDigitoRucPrivada(tercerDigito);
  }

  return false;
}

function esTercerDigitoCedulaValido(tercerDigito) {
  return !isNaN(tercerDigito) && !(tercerDigito < 0 && tercerDigito > 5);
}

function verificarTercerDigitoRucNatural(tercerDigito) {
  return tercerDigito >= 0 || tercerDigito <= 5;
}

function verificarTercerDigitoRucPrivada(tercerDigito) {
  return tercerDigito === 9;
}

function verificarTercerDigitoRucPublica(tercerDigito) {
  return tercerDigito === 6;
}

function algoritmoVerificaIdentificacion(
  numeroIdentificacion,
  ultimoDigito,
  tipoIdentificacion
) {
  const sumatoria = sumarDigitosIdentificacion(
    numeroIdentificacion,
    tipoIdentificacion
  );

  const digitoVerificador = obtenerDigitoVerificador(
    sumatoria,
    tipoIdentificacion
  );

  return ultimoDigito === digitoVerificador;
}

function sumarDigitosIdentificacion(
  numeroIdentificacion,
  tipoIdentificacion
) {
  const coeficientes = obtenerCoeficientes(tipoIdentificacion);
  const identificacion = numeroIdentificacion.split('');

  let sumatoriaCocienteIdentificacion = 0;

  for (let posicion = 0; posicion < coeficientes.length; posicion++) {
    const resultado =
      parseInt(identificacion[posicion], 10) * coeficientes[posicion];

    const sumatoria = sumatoriaMultiplicacion(
      resultado,
      tipoIdentificacion
    );

    sumatoriaCocienteIdentificacion =
      sumatoriaCocienteIdentificacion + sumatoria;
  }

  return sumatoriaCocienteIdentificacion;
}

function sumatoriaMultiplicacion(
  multiplicacionValores,
  tipoIdentificacion
) {
  if (tipoIdentificacion === "CEDULA") {
    return multiplicacionValores >= 10
      ? multiplicacionValores - 9
      : multiplicacionValores;
  } else if (
    tipoIdentificacion === "RUC_PERSONA_NATURAL"
  ) {
    const identificacion = String(multiplicacionValores).split('');
    let sumatoria = 0;

    for (let posicion = 0; posicion < identificacion.length; posicion++) {
      sumatoria = sumatoria + parseInt(identificacion[posicion], 10);
    }

    return sumatoria;
  } else {
    return multiplicacionValores;
  }
}

function obtenerCoeficientes(
  tipoIdentificacion
) {
  if (
    tipoIdentificacion === "CEDULA" ||
    tipoIdentificacion === "RUC_PERSONA_NATURAL"
  ) {
    return [2, 1, 2, 1, 2, 1, 2, 1, 2];
  } else if (
    tipoIdentificacion === "RUC_SOCIEDAD_PRIVADA"
  ) {
    return [4, 3, 2, 7, 6, 5, 4, 3, 2];
  } else if (
    tipoIdentificacion === "RUC_SOCIEDAD_PUBLICA"
  ) {
    return [3, 2, 7, 6, 5, 4, 3, 2];
  } else {
    return null;
  }
}

function obtenerDigitoVerificador(
  sumatoria,
  tipoIdentificacion
) {
  let residuo = 0;

  if (
    tipoIdentificacion === "CEDULA" ||
    tipoIdentificacion === "RUC_PERSONA_NATURAL"
  ) {
    residuo = sumatoria % 10;
    return residuo === 0 ? 0 : 10 - residuo;
  } else if (
    tipoIdentificacion === "RUC_SOCIEDAD_PUBLICA" ||
    tipoIdentificacion === "RUC_SOCIEDAD_PRIVADA"
  ) {
    residuo = sumatoria % 11;
    return residuo === 0 ? 0 : 11 - residuo;
  } else {
    return null;
  }
}
