<template>
  <v-navigation-drawer
		v-model="drawerTemp"
		class="grey lighten-3"
		app
		:mini-variant.sync="mini"
		:permanent="$vuetify.breakpoint.mdAndUp"
		:temporary="$vuetify.breakpoint.smAndDown"
		style="z-index:99"
	>
		<v-list class="pt-0">
			<v-list-item class="px-2 primary">
				<v-list-item-avatar v-if="user" class="white pr-n1">
					<avatar-view size-avatar="40" store="users" :file-name="userId" size-img="xs" />
					<!-- <v-img :src="user.avatar"></v-img> -->
				</v-list-item-avatar>
				<v-list-item-title class="white--text" to="/profile">
					<!-- <router-link to="/profile" style="text-decoration:none"> -->
						<div class="white--text">
							<p class="mb-n1">{{ user && user.nombre ? user.nombre: 'no-name' }}</p>
							<p class="mb-0 caption">{{ user && user.email ? user.email: '....' }}</p>
						</div>
					<!-- </router-link> -->
				</v-list-item-title>
				<v-btn
					v-if="$vuetify.breakpoint.mdAndUp"
					icon
					dark
					@click.stop="mini = !mini"
				>
					<v-icon>fa-chevron-left</v-icon>
				</v-btn>
				<v-btn
					v-else
					dark
					icon
					@click.stop="drawerTemp = false"
				>
					<v-icon>fa-chevron-left</v-icon>
				</v-btn>
			</v-list-item>
			<v-list-item
				v-for="item in menu.filter(e => e.grupo === 'home')"
				:key="item.title"
				link
				:to="item.ruta"
			>
				<v-list-item-icon>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-group
				v-for="item in groupMenu"
				v-show="menu.filter(e => e.grupo === item.grupo).length"
				:key="item.grupo"
				v-model="item.active"
				no-action
			>
				<!-- :prepend-icon="item.icon" -->
				<template v-slot:prependIcon>
						<v-icon small>{{ item.icon }}</v-icon>
				</template>
				<template v-slot:appendIcon>
						<v-icon small>fa-chevron-up</v-icon>
				</template>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title class="subtitle-1" v-text="item.descripcion"></v-list-item-title>
					</v-list-item-content>
				</template>
				<v-list-item
					v-for="child in menu.filter(e => e.grupo === item.grupo)"
					:key="child.name"
					link
					:to="child.ruta"
					class="pl-8"
				>
					<v-list-item-icon>
						<v-icon x-small>
							{{ child.icon }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content class="ml-n8">
						<v-list-item-title class="subtitle-2 font-weight-regular" v-text="child.title"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-group>
		</v-list>
		<template v-if="$vuetify.breakpoint.smAndUp" v-slot:append>
			<div class="pa-2 primary">
				<v-btn dark  text color="primary" :icon="mini"  @click.stop="logOut">
					<v-icon small :left="!mini" color="white">
						fa-arrow-right-from-bracket
					</v-icon>
					<span v-if="!mini" class="white-text">Cerrar Sesión</span>
				</v-btn>
			</div>
		</template>
		<div v-if="$vuetify.breakpoint.xsOnly" class="px-2 py-5 primary">
			<v-btn text color="primary" :icon="mini" @click.stop="logOut">
				<v-icon small :left="!mini" color="white">
					fa-arrow-right-from-bracket
				</v-icon>
				<span v-if="!mini" class="white-text">Cerrar Sesión</span>
			</v-btn>
		</div>
	</v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AvatarView from '@/components/base/AvatarView.vue'
import { db, auth } from '@/plugins/firebase'
import { signOut } from 'firebase/auth'
import { collection, getDocs,  query, orderBy } from '@firebase/firestore'

export default {
	name: 'DrawerMenu',
	components: {
		AvatarView
	},
	props: {
		rol: {
			type: String,
			require: false,
			default: 'na'
		},
		drawer: {
			type: Boolean,
			require: true
		}
	},
	data: () => ({
    mini: false,
		drawerTemp: false,
    tabMenu: null,
		groupMenu: [],
    items: [
			{
				title: 'Dashboard',
				icon: 'fa-chart-simple',
				ruta: '/dashboard',
				name: 'dashboard',
				grupo: 'home'
			},
			{
				title: 'Export',
				icon: 'fa-server',
				ruta: '/export-csv',
				name: 'export-csv',
				grupo: 'home'
			},
			{
				title: 'Kiosko',
				icon: 'fa-store',
				ruta: '/kiosko',
				name: 'kiosko',
				grupo: 'home'
			},
			{
				title: 'Configuración',
				icon: 'fa-cog',
				ruta: '/configuration',
				name: 'configuration',
				grupo: 'configuracion'
			},
			{
				title: 'Configurar utilitarios',
				icon: 'fa-cogs',
				ruta: '/configurar-utils',
				name: 'configurar-utils',
				grupo: 'configuracion'
			},
			{
				title: 'Mesas',
				icon: 'fa-tablets',
				ruta: '/mesas',
				name: 'mesas',
				grupo: 'configuracion'
			},
			{
				title: 'Categorías',
				icon: 'fa-layer-group',
				ruta: '/categories',
				name: 'categories',
				grupo: 'configuracion'
			},
      {
				title: 'Gestionar Ordenes',
				icon: 'fa-check-to-slot',
				ruta: '/admin-orders',
				name: 'admin-orders',
				grupo: 'home'
			},
      {
				title: 'Mis Ordenes',
				icon: 'fa-solid fa-utensils',
				ruta: '/my-orders',
				name: 'my-orders',
				grupo: 'home'
			},
      {
				title: 'Tomar Orden',
				icon: 'fa-clipboard',
				ruta: '/take-order',
				name: 'ordenes',
				grupo: 'servicio'
			},
      {
				title: 'Tomar Orden Board',
				icon: 'fa-clipboard',
				ruta: '/take-order-board',
				name: 'take-order-board',
				grupo: 'servicio'
			},
			{
				title: 'Cocina',
				icon: 'fa-bell-concierge',
				ruta: '/kitchen-room',
				name: 'kitchen-room',
				grupo: 'servicio'
			},
			{
				title: 'Bar',
				icon: 'fa-beer-mug-empty',
				ruta: '/bar-room',
				name: 'bar-room',
				grupo: 'servicio'
			},
			{
				title: 'Dividir orden',
				icon: 'fa-up-right-and-down-left-from-center',
				ruta: '/order-divider',
				name: 'order-divider',
				grupo: 'servicio'
			},
			{
				title: 'Clientes',
				icon: 'fa-people-group',
				ruta: '/clientes',
				name: 'clientes',
				grupo: 'crm'
			},
			{
				title: 'Inventario',
				icon: 'fa-bowl-rice',
				ruta: '/inventario',
				name: 'inventario',
				grupo: 'inventario'
			},
			{
				title: 'Ingredientes',
				icon: 'fa-cubes-stacked',
				ruta: '/ingredientes',
				name: 'ingredientes',
				grupo: 'inventario'
			},
      {
				title: 'Ingresos y egresos',
				icon: 'fa-right-left',
				ruta: '/ingresos-egresos',
				name: 'ingresos-egresos',
				grupo: 'inventario'
			},
			{
				title: 'Proveedores',
				icon: 'fa-people-carry-box',
				ruta: '/proveedores',
				name: 'proveedores',
				grupo: 'inventario'
			},
			{
				title: 'Cambiar Impuestos',
				icon: 'fa-percent',
				ruta: '/cambiarimpuestos',
				name: 'cambiarimpuestos',
				grupo: 'inventario'
			},
			{
				title: 'Gestionar cajas',
				icon: 'fa-solid fa-wallet',
				ruta: '/caja/gestionar-caja',
				name: 'gestionar-caja',
				grupo: 'control'
			},
			{
				title: 'Egreso caja',
				icon: 'fa-money-check-dollar',
				ruta: '/caja/egresos-caja',
				name: 'egresos-caja',
				grupo: 'control'
			},
			{
				title: 'Facturacion manual',
				icon: 'fa-file-invoice',
				ruta: 'facturacion-manual',
				name: 'facturacion-manual',
				grupo: 'control'
			},
			{
				title: 'Ordenes pagadas',
				icon: 'fa-circle-dollar-to-slot',
				ruta: '/ordenespagadas',
				name: 'ordenespagadas',
				grupo: 'control'
			},
			{
				title: 'Comprobantes',
				icon: 'fa-file-invoice-dollar',
				ruta: '/comprobantes',
				name: 'comprobantes',
				grupo: 'control'
			},
			{
				title: 'Eliminados de orden',
				icon: 'fa-trash',
				ruta: '/items-eliminados',
				name: 'items-eliminados',
				grupo: 'control'
			},
			{
				title: 'Ítems de cortesía',
				icon: 'fa-gift',
				ruta: '/items-cortesia',
				name: 'items-cortesia',
				grupo: 'control'
			},
			{
				title: 'PayPhone',
				icon: 'fa-dollar-sign',
				ruta: '/payphone',
				name: 'payphone',
				grupo: 'control'
			},
			{
				title: 'Gestion de usuarios',
				icon: 'fa-users',
				ruta: '/users',
				name: 'users',
				grupo: 'usuarios'
			},
			{
				title: 'Roles',
				icon: 'fa-shield',
				ruta: '/rol',
				name: 'rol',
				grupo: 'usuarios'
			},
			{
				title: 'Password',
				icon: 'fa-key',
				ruta: '/password',
				name: 'password',
				grupo: 'usuarios'
			},
			{
				title: 'Más vendidos',
				icon: 'fa-ranking-star',
				ruta: '/kpi/topvendidos',
				name: 'topvendidos',
				grupo: 'kpis'
			},
			{
				title: 'Uso de inventario',
				icon: 'fa-clipboard-check',
				ruta: '/kpi/usoinventario',
				name: 'usoinventario',
				grupo: 'kpis'
			},
			{
				title: 'Uso de ingredientes',
				icon: 'fa-bowl-rice',
				ruta: '/kpi/usoingredientes',
				name: 'usoingredientes',
				grupo: 'kpis'
			},
			{
				title: 'Parámetros fidelización',
				icon: 'fa-arrows-down-to-people',
				ruta: '/fidelizacion/parametros-fidelizacion',
				name: 'parametros-fidelizacion',
				grupo: 'crm'
			},
			{
				title: 'Tipos de descuentos',
				icon: 'fa-hand-holding-dollar',
				ruta: '/tipos-descuentos',
				name: 'tipos-descuentos',
				grupo: 'configuracion'
			}
		],
		rutasMini: ['kiosko', 'take-order-board']
  }),
  computed: {
		...mapGetters(['negocio', 'user', 'userId']),
		menu () {
			const auxMenu = []
			// console.log('this.negocio', this.negocio)
			// console.log('this.user', this.user)
			this.items.map(item => {
				if(this.user.rutas.includes(item.name))
					auxMenu.push(item)
			})
			return auxMenu
		}
  },
	watch: {
		drawer (newVal) {
			if (newVal) {
				this.drawerTemp = newVal
				this.mini = false
			}
		},
		drawerTemp (newVal) {
			if (!newVal) {
				this.$emit('update:drawer', false)
			}
		},
		'$vuetify.breakpoint.name' (newVal) {
			if (newVal === 'md') {
				this.mini = true
			}
		},
		'$route.name' (newVal) {
			if (this.rutasMini.includes(newVal)) {
				this.mini = true
			}
		}
	},
	created () {
		this.drawerTemp = this.drawer
		this.getDrawer()
	},
	mounted () {
		if (this.$vuetify.breakpoint.name === 'md') {
			this.mini = true
		}
	},
  methods: {
    ...mapActions(['logout']),
		logOut () {
			try {
				signOut(auth).then(() => {
					this.logout()
					this.$nextTick(()=>{
						this.$router.push('/')
					})
				})
			} catch {
				this.$nextTick(()=>{
					this.$router.push('/')
				})
			}
    },
		async getDrawer(){
      try {
        let queryPost = query(collection(db, 'drawer'), orderBy('order'))
        let querySnapshot = await getDocs(queryPost)
				this.groupMenu = []
        querySnapshot.forEach((doc) => {
          this.groupMenu.push({...doc.data()})
				})
      } catch {
				return
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.line--text {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
  align-content: start !important;
}
.white-text {
	color: white;
}
</style>>
