function aleatorio(minimo, maximo = 1000) {
  return Math.floor(Math.random() * (maximo + 1 - minimo) + minimo).toString();
}

function roundToTwo(num, decimales = 2) {
  try {
    if (isNaN(num)) {
      return 0
    }
    var signo = (num >= 0 ? 1 : -1);
    num = num * signo;
    if (decimales === 0) {
        //con 0 decimales
        return signo * Math.round(num);
    }
    // round(x * 10 ^ decimales)
    num = num.toString().split('e');
    num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
    // x * 10 ^ (-decimales)
    num = num.toString().split('e');
    return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
  } catch {
    return num
  }
}

export { aleatorio, roundToTwo };
