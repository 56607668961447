import Vue from 'vue'
import Index from './Index.vue'
// import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { firebase } from './plugins/firebase'
import './plugins/filters'
import VueCountdown from '@chenfengyuan/vue-countdown'
import QRCodeVue from 'qrcode.vue'
import createStore from './store'
import VueHtml2pdf from 'vue-html2pdf'
import '@fortawesome/fontawesome-free/css/all.css'
import NotificationToast from '@/components/base/NotificationsToast'

// import './plugins/funciones-base'
// import './plugins/toast'

import './plugins/vuetify-mask'
import './plugins/apexchart'
import './plugins/youtube-vue'
import './moment'
import './vee-validate'
import './plugins/axios'
import 'devextreme/dist/css/dx.light.css';


import './directives/print'

// require('dotenv').config()

import './registerServiceWorker'

Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
    })
  },
})

Vue.directive('sritext', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      // eslint-disable-next-line no-useless-escape
      const regex = /[^a-zA-Z0-9\s\-_\/]/g;
      e.target.value = e.target.value.replace(regex, '')
      vnode.componentInstance.$emit('input', e.target.value)
    })
  },
})

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.config.productionTip = false
Vue.use(VueHtml2pdf)
Vue.component('vue-countdown', VueCountdown)
Vue.component('qrcode-vue', QRCodeVue)
Vue.component('vue-notifications', NotificationToast)

createStore().then(store => {
  // console.log(store)
  new Vue({
    router,
    store,
    render: h => h(Index),
    vuetify,
  }).$mount('#app')  
})

export default { firebase }
