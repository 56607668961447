<template>
  <v-container fluid fill-height class="pb-15" id="viewsabores">
    <v-row class="justify-end">
      <v-col cols="6" md="6" class="justify-center d-flex ">
        <v-avatar v-if="negocio && negocio.logoURL" tile height="120" width="80%" elevation-10>
          <v-img
            eager
            referrerpolicy="no-referrer"
            v-bind:src="negocio.logoURL"
            class="image-item"
            contain
          >
          </v-img>
        </v-avatar>
        <v-avatar v-else tile height="120" width="80%" elevation-10>
          <v-img
            eager
            referrerpolicy="no-referrer"
            src="logo.png"
            class="image-item"
            contain
          >
          </v-img>
        </v-avatar>
      </v-col>
      <v-col cols="6" sm="3" md="5" lg="4" class="pa-0 d-flex justify-end">
        <div class="white pt-5 pl-10 pr-5 rounded-tl-xl primary--text">
          <p class="mb-0 font-weight-black text-right" :class="$vuetify.breakpoint.smAndDown ? 'title':'display-2'">
            Menú
          </p>
          <p class="mb-0 display-3 font-weight-black" :class="$vuetify.breakpoint.smAndDown ? 'display-1':'display-3'">
            Digital
          </p>
          <v-divider></v-divider>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-end">
      <v-col cols="auto">
        <v-btn
          icon
          @click="copyClipboard"
        >
          <v-icon>
            fa-share-nodes
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                fa-qrcode
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-row class="justify-end">
                <v-col cols="auto" class="pr-5">
                  <p class="caption font-weight-bold mb-0 text-right">
                    <v-icon x-small>fa-qrcode</v-icon>
                    QR-Code
                  </p>
                  <qrcode-vue
                    :value="thisUrl"
                    :size="150"
                    background="white"
                  />
                  <!-- foreground="#1976d2" -->
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-show="$vuetify.breakpoint.mdAndUp">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                @click="generarPdf = true"
              >
                <v-icon left x-small>fa-print</v-icon>
                print
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="auto">
        <v-btn
          depressed
          @click="dialogReservar = true"
        >
          <v-icon small left>
            fa-calendar-check
          </v-icon>
          HACER RESERVACIÓN
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" lg="10" class="text-center">
        <v-tabs v-model="tabMenu" centered>
          <v-tab class="title black--text font-weight-bold">
            <v-icon left small>fa-utensils</v-icon>
            MENÚ
          </v-tab>
          <v-tab class="title black--text font-weight-bold">
            <v-icon left small>fa-beer-mug-empty</v-icon>
            BEBIDAS
          </v-tab>
          <v-tab class="title black--text font-weight-bold">
            <v-icon left small>fa-cookie-bite</v-icon>
            SNACKS
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <v-sheet
          class="mx-auto d-flex justify-center"
        >
          <v-slide-group
            v-model="categoriesSelected"
            show-arrows
            center-active
            mandatory
          >
            <v-slide-item
              v-for="item in categoriesTipo"
              :key="item.nombre"
              :value="item.nombre"
              small
              v-slot="{ active, toggle }"
            >
              <v-btn
                class="mx-2"
                :input-value="active"
                active-class="info white--text font-weight-bold"
                depressed
                rounded
                small
                @click="toggle"
              >
                {{ item.nombre }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
      <v-col cols="12" lg="10" class="imgbkMenu rounded">
        <v-window
          v-model="tabMenu"
        >
          <v-window-item>
            <v-card v-if="itemsMenu.length" flat color="transparent">
              <v-card-text>
                <v-row dense>
                  <v-col
                    v-for="(item, i) in itemsMenu"
                    :key="i"
                    cols="6"
                    sm="4"
                    md="3"
                    lg="3"
                    xl="2"
                  >
                    <card-product-item :key="item.id" :producto="item" @verDataItem="verDataItem(item)"></card-product-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-sheet v-else height="150" class="d-flex justify-center align-center" color="transparent">
              <div class="text-center text--disabled">
                <v-icon large>
                  fa-hourglass-empty
                </v-icon>
                <p class="mt-5 display-1 text-center">
                  Nada por acá!!
                </p>
              </div>
            </v-sheet>
          </v-window-item>
          <v-window-item>
            <v-card v-if="itemsBebida.length" flat color="transparent">
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="item in itemsBebida"
                    :key="item.id"
                    cols="6"
                    sm="4"
                    md="3"
                    lg="3"
                    xl="2"
                  >
                    <card-product-item :key="item.id" :producto="item" @verDataItem="verDataItem(item)"></card-product-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-sheet v-else height="150" class="d-flex justify-center align-center" color="transparent">
              <div class="text-center text--disabled">
                <v-icon large>
                  fa-hourglass-empty
                </v-icon>
                <p class="mt-5 display-1 text-center">
                  Nada por acá!!
                </p>
              </div>
            </v-sheet>
          </v-window-item>
          <v-window-item>
            <v-card v-if="itemsSnack.length" flat color="transparent">
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="item in itemsSnack"
                    :key="item.id"
                    cols="6"
                    sm="4"
                    md="3"
                    lg="3"
                    xl="2"
                  >
                    <card-product-item :key="item.id" :producto="item" @verDataItem="verDataItem(item)"></card-product-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-sheet v-else height="150" class="d-flex justify-center align-center" color="transparent">
              <div class="text-center text--disabled">
                <v-icon large>
                  fa-hourglass-empty
                </v-icon>
                <p class="mt-5 display-1 text-center">
                  Nada por acá!!
                </p>
              </div>
            </v-sheet>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="auto">
        <div class="info-impuestos subtitle-2">
          <v-icon small color="#809DD9" class="mt-n1">fa-info-circle</v-icon>
          Los precios no incluyen impuestos
        </div>
      </v-col>
    </v-row>
    <agendar-reserva :state.sync="dialogReservar" />
    <v-overlay :value="loading">
      <v-container fluid class="align-center">
        <v-progress-circular
          indeterminate
          color="white"
          size="100"
        ></v-progress-circular>
      </v-container>
    </v-overlay>
    <v-footer absolute fixed class="px-5" color="white" height="50">
      <v-row dense class="justify-end align-end">
        <v-col
          cols="6"
          md="2"
          class="text-right"
        >
          <span class="font-weight-bold mr-2">ORDERNAR POR:</span>
        </v-col>
        <v-col cols="6" md="3" xl="2">
          <v-select
            v-model="ordenamiento"
            :items="ordenarPor"
            outlined
            hide-details
            dense
          >
          </v-select>
        </v-col>
      </v-row>
    </v-footer>
    <notifications-toast
      :data.sync="dataMessage"
      :state.sync="stateMessage"
    />
    <QrMenuPagePdf :generar-pdf.sync="generarPdf" />
    <item-menu-detail v-if="showDataItem" :data-item="showDataItem" :state.sync="dialogDataItem"></item-menu-detail>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { db } from '@/plugins/firebase'
import { collection, query, onSnapshot, orderBy, where, getDocs } from "firebase/firestore"
import NotificationsToast from '@/components/base/NotificationsToast.vue'
import AgendarReserva from   './comunes/AgendarReserva'
import QrMenuPagePdf from './comunes/QrMenuPagePdf'
import CardProductItem from './comunes/CardProductItem'
import { copyTextToClipboard } from '@/functions/copyClipBoard'
import ItemMenuDetail from './comunes/ItemMenuDetail.vue'

export default {
  name:'MenuDigital',
  components: {
    CardProductItem,
    AgendarReserva,
    NotificationsToast,
    QrMenuPagePdf,
    ItemMenuDetail
  },
  data: () => ({
    igmLoaded: false,
    dialogReservar: false,
    tabMenu: null,
    generarPdf: false,
    items: [],
    showDataItem: null,
    dialogDataItem: false,
    categorias: [],
    loading: false,
    suscribeData: null,
    categoriesSelected: null,
    ordenarPor: [
      {
        text:'POPULARIDAD ASC',
        value: 'likes-asc'
      },
      {
        text: 'POPULARIDAD DES',
        value: 'likes-desc'
      },
      {
        text: 'PRECIO ASC',
        value: 'precio-asc'
      },
      {
        text: 'PRECIO DES',
        value: 'precio-desc'
      },
      {
        text: 'NOMBRE ASC',
        value: 'nombre-asc'
      },
      {
        text: 'NOMBRE DES',
        value: 'nombre-desc'
      }
    ],
    ordenamiento: 'likes-desc',
    dataMessage: {},
    stateMessage: false,
  }),
  computed: {
    ...mapGetters(['negocio']),
    thisUrl () {
      if (window && window.location) {
        return window.location.origin + this.$route.fullPath
      } else {
        return null
      }
    },
    tipo () {
      switch (this.tabMenu) {
        case 0:
          return 'Menú'
        case 1:
          return 'Bebida'
        case 2:
          return 'Snack'
        default:
          return 'Menú'
      }
    },
    categoriesTipo () {
      let categoryAux = this.categorias.filter(x => x.tipo === this.tipo)
      const newElement = {
        nombre: "Todos",
        tipo: this.tipo
      }
      categoryAux = [newElement].concat(categoryAux)
      return categoryAux
    },
    itemsMenu () {
      if (this.categoriesSelected && this.categoriesSelected.length && this.categoriesSelected !== 'Todos') {
        return this.items.filter(x => x.tipo === 'Menú' && this.categoriesSelected.includes(x.categoria))
      } else {
        return this.items.filter(x => x.tipo === 'Menú')
      }
    },
    itemsBebida () {
      if (this.categoriesSelected && this.categoriesSelected.length && this.categoriesSelected !== 'Todos') {
        return this.items.filter(x => x.tipo === 'Bebida' && this.categoriesSelected.includes(x.categoria))
      } else {
        return this.items.filter(x => x.tipo === 'Bebida')
      }
    },
    itemsSnack () {
      if (this.categoriesSelected && this.categoriesSelected.length && this.categoriesSelected !== 'Todos') {
        return this.items.filter(x => x.tipo === 'Snack' && this.categoriesSelected.includes(x.categoria))
      } else {
        return this.items.filter(x => x.tipo === 'Snack')
      }
    }
  },
  watch: {
    ordenamiento () {
      this.getData()
    },
    tabMenu () {
      this.categoriesSelected = null
    },
    categoriesTipo () {
      this.categoriesSelected = 'Todos'
    }
  },
  created () {
    this.getCategories()
    this.getData()
  },
  beforeDestroy () {
    if (this.suscribeData) {
      this.suscribeData()
    }
  },
  methods: {
    verDataItem (item) {
      // this.showDataItem = item
      // this.dialogDataItem = true
      console.log(item)
    },
    copyClipboard () {
      copyTextToClipboard(window.location.href).then((res) => {
        if (res) {
          this.dataMessage = {
            message: 'URL copiada al portapales. Ya puede compartirlo!!',
            duration: 2500,
            type: 'success'
          }
          this.stateMessage = true
        } else {
          this.dataMessage = {
            message: 'No se pudo procesar la acción...',
            duration: 1500,
            type: 'warning'
          }
          this.stateMessage = true
        }
      }).catch((err) => {
        this.$debugError(err)
        // this.mensajesSnack = [this.$obtenerMensajeEstilizado(err)]
      })
    },
    async getData() {
      try {
        this.loading = true
        const q = query(collection(db, 'inventario'),
          where("estado", "==", "publicado"),
          orderBy(this.ordenamiento.split('-')[0], this.ordenamiento.split('-')[1])
        )
        this.suscribeData = onSnapshot(q, (querySnapshot) => {
          const tempItems = []
          let itemTemp = null
          querySnapshot.forEach((doc) => {
            itemTemp = doc.data()
            itemTemp.id = doc.id
            tempItems.push(itemTemp)
            itemTemp = null
          })
          this.items = tempItems
          this.loading = false
        })
      } catch {
        this.loading = false
      }
    },
    async getCategories() {
      try {
				let queryPost = query(collection(db, 'categories'),
					where("active", "==", true),
					orderBy("tipo", "asc")
				)
				this.querySnapshot = await getDocs(queryPost)
				this.querySnapshot.forEach((doc) => {
					this.categorias.push(doc.data())
				})
      } catch {
        this.categorias = []
      }
    },
  }
}
</script>
<style lang="scss" scoped>
// .imgbk {
//   background-image: url("/public/menu/header.jpg");
//   height: 200px;
//   background-repeat: repeat;
//   background-color: #FFFFFF;
// }
.imgbkMenu {
  background-image: url("/public/menu/background.png");
  height: 100%;
  background-repeat: repeat;
  background-color: white;
}
.title-product {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  align-content: start;
}
.descrition-product {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  align-content: start;
}
.info-impuestos {
  opacity: 60%;
  color: #809DD9;
  // border: 1px dotted grey;
  border-radius: 4px;
  padding: 8px 15px;
  background-color: #DBEAFF;
  // background-color: #F7FFE5;
}
</style>
