const {
  ValidacionCedulaRucService
} = require('../functions/validacionesCiRuc')

module.exports = {
  at_least_cant_number: {
    params: ['cant'],
    validate: (value, { cant }) => {
      return new RegExp('\\d{' + cant + ',}', 'igm').test(value)
    }
  },
  at_least_cant_char: {
    params: ['cant'],
    validate: (value, { cant }) =>
      new RegExp('[a-z]{' + cant + ',}', 'igm').test(value)
  },
  less_than: {
    params: ['otherValue'],
    validate: (value, { otherValue }) => value < parseFloat(otherValue)
  },
  more_than: {
    params: ['otherValue'],
    validate: (value, { otherValue }) => value > parseFloat(otherValue)
  },
  decimal: {
    params: ['decimals', 'separator'],
    validate: (value, { decimals = '*', separator = '.' } = {}) => {
      if (value === null || value === undefined || value === '') {
        return {
          valid: false
        }
      }
      if (Number(decimals) === 0) {
        return {
          valid: /^-?\d*$/.test(value)
        }
      }
      const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
      const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

      return {
        valid: regex.test(value)
      }
    }
  },
  validar_cedula_ruc: {
    validate: (value) => ValidacionCedulaRucService(value)
  },
  validar_dato: {
    params: ['dato'],
    validate: (value, { dato }) => value === dato
  }
}
