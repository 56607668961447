// import { auth } from '@/plugins/firebase'
// import { onAuthStateChanged } from "firebase/auth";

import createStore from '@/store'

export default function log({ next, to }) {
  // verifyUser()
  getDataUser()
  .then(dataUser => {
    if(!dataUser || !dataUser.rutas) {
      return next('/')
    }
    if(!dataUser.rutas.includes(to.name)) {
      return next('/dashboard')
    }
  })
  return next();
}

async function getDataUser() {
  let dataUser = null
  await createStore().then(store => {
    if(!dataUser)
      dataUser = store.getters.user
  })
  return dataUser
}

// async function verifyUser () {
//   await createStore().then(store => {
//     const token = store.getters.token
//     onAuthStateChanged(auth, (user) => {
//       if (user) {
//         console.log('token',token)
//         console.log('user.accessToken', user.accessToken)
//         if (user.accessToken !==  token)
//           console.log('SON DIFERENTES')
//         else
//           console.log('SON IGUALES')
//       }
//     })
//   })
// }