export function redondeaNumero (value, decimals = 0) {
  if (typeof value === 'number' && typeof decimals === 'number') { return value.toFixed(decimals) }
  return 0
}
export function obtenerEntero (value) {
  let result = '0'
  if (value) {
    if (typeof value !== 'string') {
      value = value.toString()
    }
    result = value.split('.')[0]
  }
  return result
}
export function obtenerParteDecimal (value) {
  let decimal = '00'
  if (value) {
    if (typeof value !== 'string') {
      value = value.toString()
    }
    if (value.split('.')[1]) {
      decimal = value.split('.')[1]
      if (decimal.length === 1) {
        decimal += '0'
      }
    }
  }
  return decimal.slice(0,2)
}
export function valorMonetario (value) {
  if (value) { return parseFloat(value).toFixed(2) } else { return '0.00' }
}
