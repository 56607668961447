<template>
  <v-app class="white">
    <drawer-menu v-if="logeado && showMenu" :rol="userRol" :drawer.sync="drawer" />
    <v-app-bar
      v-if="visibleTopBar"
      :flat="!isFlat"
      :app="logeado"
      style="z-index: 9"
      max-height="56"
      :dark="!logeado"
      :color="!logeado ? 'primary':''"
    >
      <!-- :class="logeado ? 'primary':''" -->
      <v-app-bar-nav-icon v-if="logeado && $vuetify.breakpoint.smAndDown" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link class="d-flex align-center" to="/">
        <v-avatar tile height="32" width="100">
          <logoBN :isDark="logeado"></logoBN>
        </v-avatar>
      </router-link>
      <span v-if="logeado && $vuetify.breakpoint.smAndUp" class="ml-1 text--disabled caption mt-4" >v3.7</span>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <v-btn
        v-show="isHome"
        text
        to="/login"
      >
        Login
        <v-icon right>fa-arrow-right-to-bracket</v-icon>
      </v-btn>
      <v-btn
        v-show="isLogin"
        text
        to="/"
      >
        Home
        <v-icon right>fa-house</v-icon>
      </v-btn>
      <div
        v-if="logeado"
      >
        <v-btn
          v-if="tomarOrden"
          :icon="$vuetify.breakpoint.smAndDown"
          color="primary"
          to="/take-order"
        >
          <v-icon :left="$vuetify.breakpoint.mdAndUp">
            fa-regular fa-clipboard
          </v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">orden</span>
        </v-btn>
        <stock-btn v-if="verStoks"></stock-btn>
        <printer-btn v-if="verImpresiones"></printer-btn>
        <!-- <notification-btn></notification-btn> -->
        <btn-estado-caja :apertura-caja="aperturaCaja"></btn-estado-caja>
        <!-- <v-btn
          v-if="$vuetify.breakpoint.mdAndDown"
          icon
          color="black"
          dark
          @click.stop="logOut"
        >
          <v-icon left>
            fa-arrow-right-from-bracket
          </v-icon>
        </v-btn> -->
      </div>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <footer-site v-if="isHome" />
    <v-fab-transition v-if="visibleGoTop">
      <v-btn
        v-show="isFlat"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        class="mb-10"
        color="primary" 
        @click="goTop"
      >
        <v-icon small>
          fa-chevron-up
        </v-icon>
      </v-btn>
    </v-fab-transition>
    <update-app />
    <no-service-available :errorType="noDataAvailable" />
  </v-app>
</template>

<script>
import DrawerMenu from '@/components/base/DrawerMenu.vue'
import FooterSite from '@/components/base/FooterSite.vue'
import UpdateApp from '@/components/UpdateApp'
import { mapGetters,mapActions } from 'vuex'
import { signOut } from 'firebase/auth'
import { db, auth } from '@/plugins/firebase'
import { onSnapshot, doc } from '@firebase/firestore'
import LogoBN from './components/logos/LogoBN.vue'
// import NotificationBtn from './components/notifications/NotificationBtn.vue'
import BtnEstadoCaja from './components/base/BtnEstadoCaja.vue'
import PrinterBtn from './components/prints/PrinterBtn.vue'
import StockBtn from './components/stock/StockBtn.vue'
import NoServiceAvailable from './components/base/NoServiceAvailable.vue'
// import ToastNotificacion from '@/components/base/ToastNotificacion.vue'

export default {
  name: 'AppIndex',
  components: {
    DrawerMenu,
    FooterSite,
    UpdateApp,
    LogoBN,
    BtnEstadoCaja,
    // NotificationBtn,
    PrinterBtn,
    StockBtn,
    NoServiceAvailable
  },
  data: () => ({
    isFlat: false,
    drawer: false,
    notificaciones: [],
    noGoTop: ['comprobante'],
    noTopBar: ['kiosko', 'backwindow'],
    noMenu: ['backwindow'],
    docUserRef: null,
    suscribeNegocio: null,
    noDataAvailable: null
  }),
  computed: {
    ...mapGetters(['logeado', 'userRol', 'negocio', 'user', 'aperturaCaja', 'userId']),
    showMenu () {
      return !this.noMenu.includes(this.$route.name)
    },
    isHome () {
      return this.$route.name === 'home'
    },
    isLogin () {
      return this.$route.name === 'login'
    },
    verImpresiones () {
      try {
        return this.user.rutas.includes('prints')
      } catch {
        return false
      }
    },
    verStoks () {
      try {
        return this.user.rutas.includes('stocks')
      } catch {
        return false
      }
    },
    tomarOrden () {
      try {
        return this.user.rutas.includes('ordenes')
      } catch {
        return false
      }
    },
    visibleGoTop () {
      try {
        return !this.noGoTop.includes(this.$route.name)
      } catch {
        return false
      }
    },
    visibleTopBar () {
      try {
        return !this.noTopBar.includes(this.$route.name)
      } catch {
        return false
      }
    }
  },
  watch: {
    "negocio.colors" (newVal) {
      try {
        if (newVal) {
          this.$vuetify.theme.themes.light.primary = '#' + newVal.primary
          this.$vuetify.theme.themes.light.secondary = '#' + newVal.secondary
          this.$vuetify.theme.themes.light.info = '#' + newVal.info
          this.$vuetify.theme.themes.light.error = '#' + newVal.error
          this.$vuetify.theme.themes.light.warning = '#' + newVal.warning
          this.$vuetify.theme.themes.light.success = '#' + newVal.success
        }
      } catch {
        return
      }
    }
  },
  created () {
    this.getDataNegocio()
    this.watchDataCaja()
  },
  mounted () {
    if (!this.logeado && this.$route.name !== 'home') {
      this.$router.push('/')
    }
  },
  beforeDestroy () {
    if (this.suscribeNegocio) {
      this.suscribeNegocio()
    }
    if (this.docUserRef) {
      this.docUserRef()
    }
  },
  methods: {
    ...mapActions(['logout', 'setInfoUser', 'setInfoNegocio']),
    logOut () {
			try {
				signOut(auth).then(() => {
					this.logout()
					this.$nextTick(()=>{
						this.$router.push('/')
					})
				})
			} catch {
				this.$nextTick(()=>{
					this.$router.push('/')
				})
			}
    },
    onScroll (e) {
      if (typeof window === 'undefined') { return }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.isFlat = top > 150
    },
    goTop () {
      this.$vuetify.goTo(0)
    },
    async getDataNegocio () {
      try {
        this.suscribeNegocio = onSnapshot(doc(db, 'negocio', '00001'), (doc) => {
          const dataNegocio = {...doc.data(), id: doc.id }
          this.setInfoNegocio({
            dataDetails: dataNegocio
          })
        },(error) => {
          console.error('ERROR NEGOCIO:', error, error.code)
          this.noDataAvailable = error.code
        })
      } catch (er) {
        console.error('DATA NEGOCIO: ', er)
      }
    },
    async watchDataCaja () {
      try {
        if (this.logeado && this.userId) {
          this.docUserRef = onSnapshot(doc(db, 'users', this.userId), (doc) => {
            if (this.user.aperturaCaja !== doc.data().aperturaCaja) {
              this.setInfoUser({
                data: {
                  ...this.user,
                  aperturaCaja: doc.data().aperturaCaja,
                  cajaId: doc.data().cajaId,
                  fechaCaja: doc.data().fechaCaja
                }
              })
            }
          }, (error) => {
            console.error('ERROR CAJA:', error)
          })
        }
      } catch (er) {
        console.error('ERROR CAJA:', er)
      }
    },
    // procesarEventosNotificaciones (dataNotificacion) {
    //   if (dataNotificacion.evento === 'afterCreate') {
    //     this.mostrarToast({
    //       codigoInterno: dataNotificacion.codigoInterno,
    //       tipo: dataNotificacion.tipo,
    //       asunto: dataNotificacion.asunto,
    //       titulo: dataNotificacion.titulo,
    //       accionURL: dataNotificacion.accionURL,
    //       modulo: dataNotificacion.modulo,
    //       msjplan: this.$t('notificaciones.mejorePlan'),
    //       creadoEn: dataNotificacion.creadoEn
    //       // router: this.$router
    //     }, dataNotificacion.importancia)
    //     // this.getCountPendientes()
    //   }
    //   // if (dataAuditoria.evento === 'afterUpdate') {
    //   //   this.getCountPendientes()
    //   // }
    //   // if (dataAuditoria.evento === 'afterDestroy' && dataAuditoria.notificacion.nuevo) {
    //   //   this.getCountPendientes()
    //   // }
    // },
    // mostrarToast (props, importancia) {
    //   if (importancia !== 'Baja') {
    //     const type = importancia === 'Alta' ? 'error' : 'info'
    //     const timeout = importancia === 'Alta' ? 8000 : 4000
    //     this.$toast({
    //         component: ToastNotificacion,
    //         props
    //       },
    //       {
    //         maxToasts: this.maxNotificaciones,
    //         type,
    //         timeout
    //       }
    //     )
    //   }
    // },
  }
}
</script>
<style>
</style>
