<template>
  <div>
    <menu-digital></menu-digital>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import MenuDigital from '@/components/menudigital/MenuDigital'
  export default {
    name: 'HomeView',
    components: {
      MenuDigital
    },
    data: () => ({
      host: ''
    }),
    computed: {
      ...mapGetters(['negocio'])
    },
    head () {
      const pwa = {
        manifest: {
          name: `${this.negocio.nombre} | ${'Taked'}`,
          short_name: this.negocio.nombre,
          description: 'Menú digital de ' + this.negocio.nombre + ' en Taked',
          icons: [
            {
              src: '/logo_64x64.png',
              sizes: '64x64',
              type: 'image/png',
              purpose: 'any maskable'
            },
            {
              src: '/logo_120x120.png',
              sizes: '120x120',
              type: 'image/png',
              purpose: 'any maskable'
            },
            {
              src: '/logo_144x144.png',
              sizes: '144x144',
              type: 'image/png',
              purpose: 'any maskable'
            },
            {
              src: '/logo_152x152.png',
              sizes: '152x152',
              type: 'image/png',
              purpose: 'any maskable'
            },
            {
              src: '/logo_192x192.png',
              sizes: '192x192',
              type: 'image/png',
              purpose: 'any maskable'
            },
            {
              src: '/logo_384x384.png',
              sizes: '384x384',
              type: 'image/png',
              purpose: 'any maskable'
            },
            {
              src: '/logo_512x512.png',
              sizes: '512x512',
              type: 'image/png',
              purpose: 'any maskable'
            }
          ],
          start_url: this.host + '/?standalone=true',
          display: 'standalone',
          background_color: 'white',
          lang: 'es',
          mobileAppIOS: true
        }
      }
      return {
        title: this.negocio.nombre,
        meta: [
          // hid is used as unique identifier. Do not use `vmid` for it as it will not work
          {
            hid: 'description',
            name: 'description',
            content: 'Menú digital de ' + this.negocio.nombre + ' en Taked'
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content: 'Menú digital de ' + this.negocio.nombre + ' en Taked'
          },
          {
            hid: 'apple-mobile-web-app-title',
            name: 'apple-mobile-web-app-title',
            content: 'Menú digital de ' + this.negocio.nombre + ' en Taked'
          },
          {
            hid: 'og:title',
            name: 'og:title',
            content: 'Menú digital de ' + this.negocio.nombre + ' en Taked'
          },
          {
            hid: 'og:site_name',
            name: 'og:site_name',
            content: 'Menú digital de ' + this.negocio.nombre + ' en Taked'
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: '/logo_64x64.png'
          },
          {
            hid: 'og:image:secure_url',
            property: 'og:image:secure_url',
            content: '/logo_64x64.png'
          },
          {
            hid: 'og:image:alt',
            property: 'og:image:alt',
            content: 'Menú digital de ' + this.negocio.nombre + ' en Taked'
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: 'Menú digital de ' + this.negocio.nombre + ' en Taked'
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: 'Menú digital de ' + this.negocio.nombre + ' en Taked'
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: '/logo_64x64.png'
          },
          {
            hid: 'twitter:image:alt',
            name: 'twitter:image:alt',
            content: 'Menú digital de ' + this.negocio.nombre + ' en Taked'
          }, {
            hid: 'apple-mobile-web-app-capable',
            name: 'apple-mobile-web-app-capable',
            content: 'yes'
          }, {
            hid: 'apple-mobile-web-app-status-bar-style',
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black'
          }
        ],
        link: [
          {
            rel: 'shortcut icon',
            type: 'image/png',
            href: '/logo_64x64.png'
            // sizes: '64x64'
          },
          {
            rel: 'apple-touch-icon',
            type: 'image/png',
            href: '/logo_64x64.png'
            // sizes: '64x64'
          },
          {
            hid: 'icon',
            rel: 'icon',
            type: 'image/x-icon',
            href: '/logo_64x64.png'
            // sizes: '64x64'
          },
          {
            hid: 'icon',
            rel: 'icon',
            type: 'image/icon',
            href: '/logo_64x64.png'
            // sizes: '64x64'
          }, {
            hid: 'manifest',
            rel: 'manifest',
            href: `data:application/manifest+json,${encodeURIComponent(JSON.stringify(pwa.manifest))}`
          },
          // {
          //   rel: 'icon',
          //   type: 'image/svg+xml',
          //   href: '/icon.svg'
          // },
          {
            rel: 'icon',
            type: 'image/x-icon',
            href: '/logo_64x64.png'
          }
        ]
      }
    }
  }
</script>
