<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      filename="QRCode-Menu"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      @hasDownloaded="onHasDownloaded"
    >
      <!-- @progress="onProgress($event)" -->
      <section slot="pdf-content">
        <div style="display: grid; justify-content: center; margin-top: 20px;">
          <!-- <div style="grid-row: 1">
            <div style="margin-bottom: 10px; display: flex; width:700px">
              <div style="align-self: bottom; display: grid;  align-content: flex-end;">
                
              </div>
          </div> -->
          <div>
            <table style="width:700px">
              <tr
                v-for="i in 5"
                :key="i"
              >
                <td
                  v-for="i in 3"
                  :key="i"
                  style="text-align:center;"
                >
                  <qrcode-vue
                    :value="thisUrl"
                    :size="200"
                    background="white"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
export default {
  name: 'QrMenuPagePdf',
  props: {
    generarPdf: {
      type: Boolean,
      require: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    thisUrl () {
      if (window && window.location) {
        return window.location.origin + this.$route.fullPath
      } else {
        return null
      }
    }
  },
  watch: {
    generarPdf(newVal) {
      if (newVal) {
        this.generateReport()
      }
    },
  },
  methods: {
    onHasDownloaded () {
      setTimeout(() => {
        this.$emit('update:generarPdf', false)
      }, 2000)
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
<style scoped>
.caja-datos {
  padding: 5px 5px;
  border-radius: 5px;
  border: 1px solid black;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 2px 5px;
  font-size: 12px;
  width: auto;
}
.titulos {
  font-size: 13px;
  font-weight: bold;
}
.texto {
  font-size: 13px;
}
</style>